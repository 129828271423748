#keep-alive-save-btn,
#choose-sessions-btn {
    background-color: #cfe9ba;
    color: #075e54;
}
.keep-alive-dropdown-menu {
    width: max-content;
    cursor: pointer;
    left: -40% !important;
}
.keep-alive-dropdown-form-group {
    display: flex;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
}
.keep-alive-modal-header {
    font-size: 14px;
}
.keep-alive-no-active-session-msg {
    display: flex;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
}