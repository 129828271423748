#session-modal-reload-btn,
#session-modal-set-as-disconnected-btn {
    background-color: #cfe9ba;
    color: #075e54;
}
.sessions-modal-no-data-msg {
    color: #075e54;
}
.sessions-modal-header-user-name {
    font-weight: bold;
}